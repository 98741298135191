import Configuration from "../../../configuration";

const refreshDarkmode = (darkButton?:HTMLElement) => {
	if (!Configuration.darkMode) { return;}
	let darkModeOn = Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
	const getState = localStorage.getItem("darkMode");
	if(getState){
		if (getState === "dark") {
			darkModeOn = true;
		} else if(getState === "light") {
			darkModeOn = false;
		} else {
			localStorage.removeItem("darkMode");
		}
	}

	if (darkButton) {
		if(darkModeOn){
			document.body.classList.add("dark");
			darkButton.setAttribute("data-state", "dark");
		} else {
			document.body.classList.remove("dark");
			darkButton.setAttribute("data-state", "light");
		}
	}
};
refreshDarkmode();

const initDarkmode = () => {
	if (!Configuration.darkMode) { return;}
	refreshDarkmode();

	const darkButton = document.querySelector<HTMLElement>("dark-mode");
	if (!darkButton) {
		throw new Error("Can't find dark-mode button or body");
	}

	darkButton.onclick = e => {
		e.stopPropagation();
		const stateAttr = darkButton.getAttribute("data-state");
		if(stateAttr === "dark"){
			localStorage.setItem("darkMode", "light");
			refreshDarkmode(darkButton);
		} else {
			localStorage.setItem("darkMode", "dark");
			refreshDarkmode(darkButton);
		}
	};
};
setTimeout(initDarkmode, 0);
