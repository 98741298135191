import "./homepage.css";

export const initClickHeroImage = () => {
	const heroFig = document.querySelector(`figure[figure-type="hero-image"]:first-child`);
	if (!heroFig) { return;}
	const interAnkor = document.createElement("a") as HTMLAnchorElement;
	interAnkor.href = "https://www.interregemr.eu/home-de";
	interAnkor.target = "_blank";
	interAnkor.classList.add("interregemr-url");
	heroFig.after(interAnkor);
};

setTimeout(initClickHeroImage, 0);


const initRemoveNav = () => {
	const pageType = document.querySelector(`main[page-type="landing"]`);
	if (!pageType) { return;}
	document.querySelector(`#button-navigation`)?.remove();
	document.querySelector(`#navigation`)?.remove();
	document.querySelector(`internal-nav-container`)?.remove();
};
setTimeout(initRemoveNav, 0);
