import configuration from "../../../configuration";
import { isMobile } from "../../_helper";
import { hideModal } from "../../modal/modal";
import { showOverlay, hideOverlay, overlayCloseHandlers, isOverlayActive, canUserCloseOverlay } from "../../overlay/overlay";
import "./navigation.css";

const injectNavEntries = () => {
	if(configuration.target !== "lasub"){
		return;
	}
	const footernav = document.querySelector<HTMLElement>(`section[content-type-id="2558411"]`);
	if(footernav){
		footernav.style.display = "none";
	}
	const navItem = document.querySelector<HTMLElement>(`#navigation li[page-id="2265670"]`);
	if (!footernav || !navItem) { return;}

	const allList = Array.from(footernav.querySelectorAll<HTMLElement>("ul"));
	for (const uList of allList) {
		uList.classList.add("nav-format-3");
		navItem.append(uList);
	}
};
injectNavEntries();

const initNavBar = () => {
	const navBar = document.querySelector<HTMLElement>("body > aside");
	const navButton = document.querySelector("#button-navigation");
	if((!navBar) || (!navButton)){return;}

	const navCloseButton = document.createElement("NAV-CLOSE-BUTTON");
	navBar.append(navCloseButton);
	navCloseButton.addEventListener("click", () => {
		navBar.classList.remove("active");
		navBar.setAttribute('aria-hidden', "true");
		hideOverlay();
	});
	navBar.setAttribute('aria-hidden', "true");

	navButton.addEventListener("click", () => {
		if(isOverlayActive() && !canUserCloseOverlay()){
			return;
		}
		hideModal();
		if (navBar.classList.contains("active")) {
			navBar.classList.remove("active");
			navBar.setAttribute('aria-hidden', "true");
			hideOverlay();
		} else {
			navBar.classList.add("active");
			showOverlay(navBar);
			navBar.setAttribute('aria-hidden', "false");
			if(configuration.enableSearch){
				const searchBar = navBar.querySelector<HTMLInputElement>('input[name="sword"]');
				if(searchBar){
					if(!isMobile){
						searchBar.focus();
					}
				}
			}
		}
	});
	overlayCloseHandlers.push(() => {
		navBar.classList.remove("active");
		navBar.setAttribute('aria-hidden', "true");
	});
};
setTimeout(initNavBar, 0);

/* Don't pollute the global scope if avoidable */
(() => {
	const initNavigation = () => {
		const showNavigationActive = (ele:HTMLElement, openUl = true) => {
			if((ele === null) || ((ele.tagName !== "UL") && (ele.tagName !== "LI"))){return;}
			if(ele.tagName === "UL"){
				ele.classList.remove("hidden");
			}else{
				ele.querySelector("nav-toggle")?.classList.add("active");
			}
			if(openUl){
				for(const chi of Array.from(ele.children)){
					if(chi.tagName === "UL"){
						chi.classList.remove("hidden");
						chi.setAttribute('role', 'group');
						openUl = false;
					}
				}
			}
			if(ele.parentElement){
				showNavigationActive(ele.parentElement, openUl);
			}
		};

		const refreshNavigationList = () => {
			if(!configuration.alwaysShowEntireNavigation){
				for(const ele of Array.from(document.querySelectorAll("nav ul"))){
					ele.classList.add("hidden");
				}
				for(const ele of Array.from(document.querySelectorAll("nav"))){
					if(ele.firstElementChild){
						ele.firstElementChild.classList.remove("hidden");
					}
				}
				for(const ele of Array.from(document.querySelectorAll("nav-toggle.active"))){
					ele.classList.remove("active");
				}
				const activeEle = document.querySelector<HTMLElement>("nav li.active");
				if(activeEle){
					showNavigationActive(activeEle);
				}

				const overViewLinks = Array.from(document.querySelectorAll<HTMLElement>(`[wrap-page-type="overview"] a[page-type="overview"]`));
				for (const link of overViewLinks) {
					link.addEventListener("click", (e:MouseEvent)=> {
						e.preventDefault();
						toggleNav(link);
					});
				}

				if(configuration.openFirstNavigationLevel){
					const firstLevel = document.querySelectorAll("nav > ul > li > ul > li");
					for (let i = 0; i < firstLevel.length; i++) {
						firstLevel[i].firstElementChild?.firstElementChild?.classList.add("active");
						const ulHidden = firstLevel[i].querySelector("ul");
						if (ulHidden !== null) {
							ulHidden.classList.remove("hidden");
						}
					}
					const topLevel = document.querySelectorAll("nav > ul > li");
					for (let i = 0; i < firstLevel.length; i++) {
						if (!topLevel[i] || !topLevel[i].firstElementChild) { break; }
						topLevel[i].firstElementChild?.firstElementChild?.classList.add("active");
						const ulHidden = topLevel[i].querySelector("ul");
						if (ulHidden !== null) {
							ulHidden.classList.remove("hidden");
						}
					}
				}
			}

			const activeEntry = document.querySelector("nav a.active");
			const activeTopEntry = document.querySelector("nav li.active");
			if (activeEntry !== null) {
				activeEntry.scrollIntoView({behavior: "instant", block: "center", inline: "center"});
			}
			if (activeTopEntry !== null) {
				activeTopEntry.scrollIntoView({behavior: "instant", block: "center", inline: "center"});
			}
		};

		const toggleNav = (toggle:HTMLElement) => {
			const parentLi = toggle.parentElement?.parentElement;
			if(!parentLi){return;}
			const ul = Array.from(parentLi.children).find(t => t.tagName === "UL");
			if(!ul){return;}
			toggle.onclick = () => {
				if(ul.classList.contains("hidden")){
					ul.classList.remove("hidden");
					toggle.classList.add("active");
					parentLi.setAttribute('aria-expanded', "true");
				} else {
					ul.classList.add("hidden");
					toggle.classList.remove("active");
					parentLi.setAttribute('aria-expanded', "false");
				}
			};
		};

		document.querySelectorAll<HTMLElement>("nav-toggle").forEach(toggle => toggleNav(toggle));

		refreshNavigationList();
		overlayCloseHandlers.push(() => {
			refreshNavigationList();
		});
	};
	setTimeout(initNavigation, 0);
})();
