import { clearCookie } from "../_helper";

import "./languages.css";

export const initLanguageSwitcher = () => {
	clearCookie("LANG");

	const languageButton = document.querySelector<HTMLElement>("#language-button");
	if(!languageButton){
		return;
	}
	const langPanel = document.querySelector<HTMLElement>("#language-panel");
	if(!langPanel){
		throw new Error("Can't find language-panel");
	}
	languageButton.onclick = () => langPanel.classList.toggle("active");
};

setTimeout(initLanguageSwitcher, 0);
