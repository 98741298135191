import "./guide.css";

const initGuides = (guideWrap:Element) => {
	const guides = Array.from(guideWrap.querySelectorAll(".guide-link")) as Element[];
	guides.forEach((guide) => {
		guide.addEventListener("click", () => {
			const open = guide.classList.contains("active");
			guides.forEach(t=>t.classList.remove("active"));
			if (!open) { guide.classList.add("active");	}
		});
	});
};

export const initAllGuides = () => document.querySelectorAll("guide-wrap").forEach(initGuides);
setTimeout(initAllGuides, 0);
