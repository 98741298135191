import { apiCreateSubmission, apiGetStudentSubmissions, apiUpdateSubmission, ISubmission, IStudent } from "@digitale-lernwelten/ugm-client-lib";
import { lockExercise, unlockExercise } from "../../content_types/exercise/exercise-textarea";
import { getLastParentSection } from "../../_helper";
import { ICorrection, ICorrectionEvent, flushCorrections, emitCorrectionChanged } from "../corrections/corrections";
import { DataStore } from "../datastore";
import { getUser, isFakeStudent, isLoggedIn, isStudent } from "../user-data";
import { KvGet, KvGetAll, KvSet } from "../user-kv";

export const getAllSubmissions = () => {
	if (isLoggedIn() && isFakeStudent()){
		return Array.from(KvGetAll()).filter((d:any) =>
			(d[0] as string).startsWith('teacher-sub-')
		).map((d:any) => d[1]);
	}
	return Array.from(store.values());
};

const getDraftByExerciseId = (exerciseId:string) => {
	if (isLoggedIn() && isFakeStudent()){
		return KvGet(`teacher-sub-${exerciseId}`);
	}
	return store.find(s => (s.state === "draft") && (s.exerciseId === exerciseId));
};

export const getSubmission = (exerciseId:string) => {
	if (isLoggedIn() && isFakeStudent()){
		return KvGet(`teacher-sub-${exerciseId}`);
	}
	const arr = Array.from(store.values())
		.filter(s => !s.deletedAt && (s.exerciseId === exerciseId))
		.sort((a,b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
	return arr[0] || undefined;
};

export const getCorrectedSubmission = (exerciseId:string) => {
	if (isLoggedIn() && isFakeStudent()){
		return undefined;
	}
	const arr = Array.from(store.values())
		.filter(s => !s.deletedAt && (s.state !== "draft") && (s.exerciseId === exerciseId))
		.sort((a,b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
	return arr[0] || undefined;
};

export const getCorrection = (exerciseId: string) => {
	if (isLoggedIn() && isFakeStudent()){
		return undefined;
	}
	const sub = getCorrectedSubmission(exerciseId);
	if(!sub || !sub.correction){return undefined;}
	const cor:ICorrection = {
		exerciseId: sub.exerciseId,
		text: sub.correction.text, // used to be generalText
		// annotatedText is undefined if the teacher hasn't added any annotations
		// so just fallback to the submitted text
		annotatedText: sub.correction.annotatedText ?? sub.text
	};
	return cor;
};

export const addOrUpdateSubmission = async (sub:ISubmission) => {
	if(!sub.correction){return;}
	setTimeout(() => {
		emitCorrectionChanged(sub.exerciseId, getCorrection(sub.exerciseId));
	});
};

const doPoll = async (after?: Date) => {
	if(!isStudent()){return [];}
	const submissions = await apiGetStudentSubmissions(true, after);
	return submissions;
};

const handleCorrection = (ev:Event) => {
	const e = ev as CustomEvent;
	const correctionEvent = e.detail as ICorrectionEvent;
	unlockExercise(correctionEvent.exerciseId);
};
window.addEventListener("ugm-correction", handleCorrection);

const flushSubmissions = () => {
	/* Important that we don't call flushCorrections directly because of circular references / esm initialization order */
	setTimeout(() => flushCorrections(),0);
	const exercises = Array.from(document.querySelectorAll<HTMLElement>(`section[content-type="exercise"]`));
	exercises.forEach(exercise => {
		const exerciseId = exercise.getAttribute("content-type-id");
		if(!exerciseId){return;}
		unlockExercise(exerciseId);
	});
};

const store:DataStore<ISubmission> = new DataStore("ugm-correction", addOrUpdateSubmission, flushSubmissions, doPoll);

const newSubmission = (exerciseId:string):ISubmission => {
	const chapter = document.querySelector<HTMLElement>(`#header-center .page-title, #header-center h3`);
	const exerciseChapter = chapter?.innerText || "";

	const exercise = document.querySelector<HTMLElement>(`[content-type-id="${exerciseId}"] exercise-content > h3 `);
	const exerciseTitle = exercise?.innerText || "";

	let exerciseHref = `${document.location.origin}${document.location.pathname}`;
	const section = getLastParentSection(exercise);
	if(section){
		const marker = section.querySelector(".marker");
		if(marker){
			exerciseHref += "#" + marker.getAttribute("id");
		}
	}

	return {
		id: "",
		deletedAt: null,
		updatedAt: new Date(),
		createdAt: new Date(),
		exerciseId,
		exerciseChapter,
		exerciseTitle,
		exerciseHref,
		state: 'draft',
		text: '',
		correction: null,
		student: getUser() as IStudent
	};
};

const saveNewSubmission = async (sub:ISubmission) : Promise<ISubmission> => {
	if(isLoggedIn()){
		const ret = await apiCreateSubmission(
			sub.exerciseId,
			sub.exerciseChapter,
			sub.exerciseTitle,
			sub.exerciseHref,
			"draft",
			sub.text
		);
		addOrUpdateSubmission(sub);
		return ret;
	} else {
		addOrUpdateSubmission(sub);
		return sub;
	}
};

const updateSubmission = async (sub:ISubmission) : Promise<ISubmission> => {
	if(sub.state === "corrected"){
		return sub;
	}
	addOrUpdateSubmission(sub);
	if(isLoggedIn()){
		await apiUpdateSubmission(sub.id, sub.state as "draft" | "submitted", sub.text);
	}
	return sub;
};

const exerciseIdCreationsInFlight:Set<string> = new Set();
const exerciseIdCreationCallbackQueue:Map<string, (() => void)> = new Map();

export const setSubmission = async (exerciseId:string, data:any) => {
	if (isLoggedIn() && isFakeStudent()){
		const key = `teacher-sub-${exerciseId}`;
		KvSet(key, data);
		return;
	}
	const baseSubmission = getDraftByExerciseId(exerciseId);
	if(!baseSubmission){
		if (exerciseIdCreationsInFlight.has(exerciseId)){
			exerciseIdCreationCallbackQueue.set(exerciseId, () => {
				setSubmission(exerciseId, data);
			});
		}else{
			exerciseIdCreationsInFlight.add(exerciseId);
			const r = await saveNewSubmission({...newSubmission(exerciseId), ...data});
			store.set(r);
			exerciseIdCreationsInFlight.delete(exerciseId);
			const cb = exerciseIdCreationCallbackQueue.get(exerciseId);
			if(cb){
				exerciseIdCreationCallbackQueue.delete(exerciseId);
				cb();
			}
		}
	} else {
		const newSubmission = {...baseSubmission, ...data};
		if (JSON.stringify(newSubmission) !== JSON.stringify(baseSubmission)) {
			const r = await updateSubmission(newSubmission); // Only send an update when things have actually changed
			store.set(r);
		}
	}
};

export const submitExercise = async (exerciseId:string, text:string) => {
	try {
		lockExercise(exerciseId);
		const state = "submitted";
		await setSubmission(exerciseId, {state, text});
		setTimeout(() => {
			emitCorrectionChanged(exerciseId, getCorrection(exerciseId));
		});
	} catch {
		unlockExercise(exerciseId); // Unlock if the request failed, so the student can try again later
	}
};

export const getUnreadCorrectionCount = () => {
	const set = new Set();
	store.forEach(sub => {
		if(!sub.deletedAt && sub.state === "corrected" && sub.correction && !sub.correction.read){
			set.add(sub.exerciseId);
		}
	});
	return set.size;
};
