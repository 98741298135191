import Configuration from "../../configuration";
import "./internalnav.css";


const scrollToId = (id:string) => {
	if (!id) { return;}
	const target = document.querySelector<HTMLElement>(`[content-type-id="${id}"]`);
	if (target) {
		target.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
	}
};


const loadInternalNavi = (panel:HTMLElement) => {
	if (!panel) { return;}
	const headlines = Array.from(document.querySelectorAll<HTMLElement>(`[content-type="headline"]`));
	const hlContainer = document.createElement("hl-container");
	panel.append(hlContainer);
	headlines.forEach(headline => {
		const id = headline.getAttribute("content-type-id");
		const headlineContent = headline.querySelector<HTMLElement>("inner-content");
		if (id && headlineContent) {
			const size = headlineContent.children[0].tagName.slice(-1);
			const intSize = parseInt(size);
			if (intSize > 3) { return; } //only until healine 3
			const title = (headlineContent.children[0] as HTMLElement).innerText;
			if(!title){
				return;
			}
			const link = document.createElement("internal-link");
			link.innerText = title;
			link.setAttribute("size", size);
			link.setAttribute("reference", id);
			hlContainer.append(link);
			link.addEventListener("click", (e:Event)=>{
				e.preventDefault();
				scrollToId(id);
			});
		}
	});
};

let internalNavPanel:HTMLElement | null = null;
let internalNavContainer:HTMLElement | null = null;

const closeInternalNav = () => {
	internalNavPanel?.classList.remove("active");
	internalNavContainer?.classList.remove("active");
};

const toggleInternalNav= () => {
	if(!internalNavPanel || !internalNavContainer ){return;}
	if(internalNavPanel.classList.contains("active")){
		closeInternalNav();
	}else{
		internalNavPanel.classList.add("active");
		internalNavContainer.classList.add("active");
	}
};

export const initInternalNav = () => {
	if (!Configuration.internalNavigation) { return;}
	const header = document.querySelector("header");
	if (!header) { return;}
	const hamburger = document.querySelector("#button-navigation");
	if (!hamburger) { return;}
	internalNavPanel = document.createElement("ASIDE");
	internalNavPanel.id = "internal-nav-exit-panel";
	document.body.append(internalNavPanel);
	internalNavContainer = document.createElement("internal-nav-container");
	const navButton = document.createElement("nav-intern");
	internalNavContainer.append(navButton);
	document.body.append(internalNavContainer);
	navButton.onclick = toggleInternalNav;
	internalNavPanel.onclick = closeInternalNav;
	loadInternalNavi(internalNavContainer);
};


setTimeout(initInternalNav, 0);
