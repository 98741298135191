import "./fancy-nav.css";
import { getHistory } from "../../page-view-history/pageViewHistory";

const fancyNavSlider = (wrap:HTMLElement, children:NodeListOf<HTMLElement>) => {
	const items = 3;

	Array.from(children).slice(0,items).forEach((child:HTMLElement)=>{
		child.classList.add("active");
	});

	const updateSlider = (index:number) => {
		children.forEach((child:HTMLElement, i:number)=>{
			if (i >= index && i < index + items) {
				child.classList.add("active");
			} else {
				child.classList.remove("active");
			}
		});
	};

	let curIndex = 0;
	const maxIndex = (children.length - items > 0) ? children.length - items : 0;
	const nextBtn = wrap.querySelector<HTMLElement>("fancy-nav-next");
	const prevBtn = wrap.querySelector<HTMLElement>("fancy-nav-prev");
	if (!nextBtn||!prevBtn) { throw new Error("fancy slider no nav btns found!");}

	if (maxIndex !== 0) {
		nextBtn.classList.add("active");
	}

	nextBtn.onclick = ()=> {
		curIndex = Math.min(curIndex+1, maxIndex);
		prevBtn.classList.add("active");
		if (curIndex >= maxIndex) {
			nextBtn.classList.remove("active");
		} else {
			nextBtn.classList.add("active");
		}
		updateSlider(curIndex);

	};
	prevBtn.onclick = ()=> {
		curIndex = Math.min(curIndex-1, maxIndex);
		nextBtn.classList.add("active");
		if (curIndex < 1) {
			prevBtn.classList.remove("active");
		}
		else {
			prevBtn.classList.add("active");
		}
		updateSlider(curIndex);
	};

};

//loads the history and adds it over the index
const getFancyNavHistory = () => {
	const rawHistory = getHistory();
	return rawHistory.sort((a,b)=>a.date-b.date).map(h=>h.title);
};

const getEntryMap = () => {
	const navi = document.querySelector<HTMLElement>(`nav[role="navigation"]`);
	if (!navi) {throw new Error("no nav generated in backend!!!");}
	const allEntries = navi.querySelectorAll<HTMLElement>("a");
	if (!allEntries) { throw new Error("no links in nav generated in backend!");}
	const entryMap:Map<string,any> = new Map();
	Array.from(allEntries).filter(l=>l.innerText === "netBook Deutsch" ? '' : l).forEach(link=>{
		const entryTitle = link.innerText;
		const entryImage = link.getAttribute("page-image");
		const entryUrl = link.getAttribute("href");
		const entryOrigTitle = link.getAttribute("page-title");
		if (entryTitle && entryImage && entryUrl) {
			entryMap.set(entryTitle, { entryImage, entryUrl, entryOrigTitle});
		}
	});
	return entryMap;
};

const buildHistoryItem = (entry:any) => {
	let historyImage;
	const placeholder = entry?.entryImage;
	const videoplaceholder = entry?.entryImage?.split(".").pop() === "mp4";
	if (!placeholder || videoplaceholder) {
		historyImage = document.createElement("history-img-placeholder");
	} else {
		historyImage = document.createElement("img");
		historyImage.src = entry.entryImage;
	}
	const historyItem = document.createElement("history-item");
	const historyLink = document.createElement("a");
	const historyImageWrap = document.createElement("history-img-wrap");
	const historyTitle = document.createElement("h4");
	historyTitle.innerText = entry.entryOrigTitle;
	historyLink.href = entry.entryUrl;
	historyLink.target = "_blank";

	historyImageWrap.append(historyImage);
	historyImageWrap.append(historyTitle);
	historyLink.append(historyImageWrap);
	historyItem.append(historyLink);

	return historyItem;
};


const initFancyNavHistory = (historyWrap:HTMLElement) => {
	const historyTitles = getFancyNavHistory();
	const entryMap = getEntryMap();
	if (!historyTitles || historyTitles.length<3 || !entryMap) {
		throw new Error("no history Tag found");
	}
	historyWrap.classList.add("active");
	for (const hTitle of historyTitles.slice(0,3)) {
		const entry = entryMap.get(hTitle);
		if (entry) {
			const html = buildHistoryItem(entry);
			historyWrap.append(html);
		}
	}
};

const initFancyNav = (wrap:HTMLElement) => {
	const containers = wrap.querySelectorAll<HTMLElement>("fancy-nav-container");
	if (!containers) { throw new Error("no fancyNav Tag found!");}
	containers.forEach((row:HTMLElement)=>{
		const children = row.querySelectorAll<HTMLElement>("fancy-nav-children > inner-content > a.fancy-nav-link");
		if (!children) { return;}
		fancyNavSlider(row, children);
	});
};

export const initAllFancyNavs = () => {
	document.querySelectorAll<HTMLElement>("fancy-nav-wrap").forEach(initFancyNav);
	document.querySelectorAll<HTMLElement>("fancy-nav-history").forEach(initFancyNavHistory);
};
setTimeout(initAllFancyNavs, 0);
