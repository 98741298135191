import { findIframe } from "../../_helper";

const actionHandlers:Map<string, (iframe:HTMLIFrameElement, data:any, respond:(msg:string, data:any) => void) => void> = new Map();

actionHandlers.set("hello", (iframe:HTMLIFrameElement, data:any, respond:(msg:string, data:any) => void) => {
	iframe.style.width = '100%';
	iframe.getBoundingClientRect();
	const resize = () => {
		if (iframe.contentWindow) {
			respond('resize', null);
		} else {
			window.removeEventListener('resize', resize);
		}
	};
	window.addEventListener('resize', resize, false);
	respond('hello', null);
});

actionHandlers.set("prepareResize", (iframe:HTMLIFrameElement, data:any, respond:(msg:string, data:any) => void) => {
	if (iframe.clientHeight === data.scrollHeight && data.scrollHeight === data.clientHeight) {return;}
	iframe.style.height = data.clientHeight + 'px';
	respond('resizePrepared', null);
});

actionHandlers.set("resize", (iframe:HTMLIFrameElement, data:any) => {
	iframe.style.height = data.scrollHeight + 'px';
	iframe.dispatchEvent(new CustomEvent("content-resize",{detail:{}, bubbles: true, cancelable: true, composed: false}));
});

window.addEventListener('message', event => {
	if (event.data.context !== 'h5p') { return; }
	const iframe:HTMLIFrameElement | null = findIframe(event.source);
	if (!iframe) { return; }
	if (actionHandlers.get(event.data.action)) {
		const handler = actionHandlers.get(event.data.action);
		if (!handler) { return; }
		handler(iframe, event.data, (action, data) => {
			if (!event.source) {return;}
			data = !data ? {} : data;
			data.action = action;
			data.context = 'h5p';
			(<WindowProxy>event.source).postMessage(data, event.origin);
		});
	}
}, false);

