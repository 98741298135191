export * from '../../core';
export * from './darkmode/darkmode';
export * from './homepage/homepage';
export * from '../../core/internalnav';
export * from './landingpage';

export * from './guide/guide';
export * from './homepage/homepage';
export * from '../../core/internalnav';

import "./_typography.css";
import "./_vars.css";
import "./core.css";
import "./headerbar.css";
import "./box/box.css";
import "./download/download.css";
import "./embedding/embedding.css";
import "./exercise/exercise-textarea.css";
import './footer/footer.css';
import "./gallery/gallery.css";
import "./help/help.css";
import "./media/media.css";
import "./navigation/navigation.css";
import "./notes/notes.css";
import "./page-view-history/pageViewHistory.css";
import "./privacy/privacy.css";
import "./star-selection/star-selection.css";
import "./tab-box/tab-box.css";
import "./table/table.css";
import "./text-and-image/text-and-image.css";
import "./tiles/tiles.css";
import "./sidebar.css";

(() => {
	const url = new URL(String(window.location));
	if(url.searchParams.get("showUntranslated")){
		document.body.classList.add("showUntranslated");
	}
})();
