import { hideElementContentHandler } from "../../_helper";
import { scrollToElement } from "../../_helper";
import { showEmbeddingSections } from "../embedding/embedding";
import { initEmbeddingLinks } from "../embedding/video-embedding";
import { initGlossary } from "../glossary/glossary";
import Configuration from "../../../configuration";
import { initMedia } from "../media/media";
import { initGallery, initLightbox } from "../gallery/gallery";

import "./star-selection.css";

import star0 from "../../svg/star-0.svg";
import star1 from "../../svg/star-1.svg";
import star2 from "../../svg/star-2.svg";
import star3 from "../../svg/star-3.svg";
import starAudio from "../../svg/star-audio.svg";
import starClose from "../../svg/star-close.svg";
import starClosed from "../../svg/star-closed.svg";
import starDownload from "../../svg/star-download.svg";
import starImage from "../../svg/star-image.svg";
import starOpen from "../../svg/star-open.svg";
import starQuest from "../../svg/star-quest.svg";
import starStar from "../../svg/star-star.svg";
import starTask from "../../svg/star-task.svg";
import starText from "../../svg/star-text.svg";
import starVideo from "../../svg/star-video.svg";

const iconImages = {
	"0": star0,
	"1": star1,
	"2": star2,
	"3": star3,
	"audio": starAudio,
	"close": starClose,
	"closed": starClosed,
	"download": starDownload,
	"image": starImage,
	"open": starOpen,
	"quest": starQuest,
	"star": starStar,
	"task": starTask,
	"text": starText,
	"video": starVideo
};

const C_PROPERTY_KEY = "star-selection";
const C_VALID_STAR_MODES = ["stern", "true"];

const isStarMode = (element:Element) : boolean => {
	return C_VALID_STAR_MODES.includes(element.getAttribute(C_PROPERTY_KEY) || "");
};

interface StarSection {
	section: HTMLElement,
	starElements: HTMLElement[]
}

let AllStars = new Map();

export const getCurrentPageId = () => {
	return document.querySelector(`main[content-type="content"]`)?.getAttribute("content-type-id") as string;
};
const currentPage = getCurrentPageId();
export const readStarLevel = () => localStorage.getItem(`user-star-level-${currentPage}`) || "2";

const loadAllStars = () => {
	const localStardata = localStorage.getItem("star-data");
	if (!localStardata) {
		return AllStars;
	}
	else {
		const raw = JSON.parse(localStardata);
		const objectToMap = (object:any) => {
			const m = new Map();
			for (const key in object) {
				if (object[key] instanceof Object) {
					m.set(key, objectToMap(raw[key]) );
				} else {
					m.set(key, object[key]);
				}
			}
			return m;
		};
		AllStars = objectToMap(raw);
		return AllStars;
	}
};

const SaveAllStars = () => {
	const o = Object.fromEntries(AllStars);
	for(const key in o){
		o[key] = Object.fromEntries(o[key]);
	}
	return localStorage.setItem("star-data", JSON.stringify(o));
};

const DeleteAllStars = () => {
	AllStars.get(currentPage)?.clear();
	setTimeout(SaveAllStars, 0);
};

const unselectStarElement = (contentID: string | null) => {
	if(!contentID){return;}
	AllStars.get(currentPage)?.delete(contentID);
	setTimeout(SaveAllStars, 0);
};

/**
 * The top-offset used to scroll to star sections
 */
const C_SCROLL_OFFSET = 150;
let sections:HTMLElement[] = [];
const initStarSelection = () => {
	sections = Array.from(document.querySelectorAll<HTMLElement>("main > section"));
	const sectionsWithStars:StarSection[] = [];
	// find all sections without the property 'star-selection'
	// who have sibling elements with the property
	for (const section of sections) {
		if (isStarMode(section)) {
			continue;
		}
		let nextSib = section.nextElementSibling;
		const starElements:HTMLElement[] = [];
		// find siblings star element sibling
		while(nextSib !== null && (nextSib instanceof HTMLElement) && isStarMode(nextSib)) {
			starElements.push(nextSib);
			nextSib = nextSib.nextElementSibling;
		}
		if (starElements.length > 0) {
			sectionsWithStars.push({
				section,
				starElements
			});
		}
	}

	for(const {section, starElements} of sectionsWithStars) {
		// mark section to have star siblings
		section.setAttribute("with-stars", String(starElements.length));

		//remove infolink
		const infoLink = section.querySelector("info-link-wrap");
		if (infoLink) {
			infoLink.remove();
		}
		// remove markers from star elements (should be done in backend)
		for (const starElement of starElements) {
			const markers = Array.from(starElement.querySelectorAll(".marker"));
			const marker = markers.pop();
			if (marker) {
				marker.remove();
			}
			// mark the section as a star element sibling for the parent section
			const sectionID = section.getAttribute("content-type-id") || "";
			starElement.setAttribute('for-star', sectionID);
			//make all embedded things lazy
			hideElementContentHandler(starElement);
		}

		const hasHelpVideo = section.querySelectorAll("help-video").length > 0;
		const hasLocation = section.previousElementSibling?.getAttribute("content-type") === "location";
		const starButton = createStarButton(section);
		const container = document.createElement("div");
		container.classList.add("star-button-container");
		if (hasHelpVideo && hasLocation) {
			container.classList.add("offset-2");
		}
		if (hasHelpVideo || hasLocation) {
			container.classList.add("offset");
		}
		container.appendChild(starButton);
		const starButtons = createStarButtons(starElements, section);
		starButtons.forEach(s => {
			container.appendChild(s);
		});
		starButton.onclick = () => {
			// first reset all star buttons and star elements
			const isOpen = starButton.classList.contains("open");
			if (isOpen) {
				resetStarElements(starElements);
				//also reset the element exchange
				const insContent = section.querySelector<HTMLElement>("inner-content.inserted-content");
				if (insContent) {
					const oldContent = section.querySelector<HTMLElement>("inner-content.hidden-content");
					oldContent?.classList.remove("hidden-content");
					starElements.forEach(e=>e.classList.remove("hidden-content"));
					insContent.remove();
				}
				starButton.classList.remove("open");
				starButton.classList.remove("active");
				section.removeAttribute("style");
				// if the section is hidden, add a fade animation
				if (!isVisible(section)) {
					// because fade in is an animation now, we just want to apply it to the star-selection elements
					if (section.getAttribute("star-selection")) {
						section.classList.add("fadein");
					}
				}
				renderEmbedding(section);
				recalcAllElements();
				starElements.forEach(stopAllMedia);
				unselectStarElement(section.getAttribute("content-type-id"));
				SaveAllStars();
			} else {
				starButton.classList.add("open");
				const isInserted = Boolean(section.querySelector(".inserted-content"));
				if (!isInserted) {
					starButton.classList.add("active");
				}
				section.classList.remove("unblur");
				resetStarElements(starElements);
				//only when not in mobile (star buttons vertical)
				const starSize = (starButtons.length + (hasHelpVideo? 2 : 1)) * 64;
				if (!isMobile()) {
					if(starSize > section.offsetHeight) {
						section.style.marginBottom = starSize - section.offsetHeight + 32 + "px";
					}
				}
				recalcAllElements();
			}
			starButtons.forEach(s => {
				if (!isOpen) {
					s.classList.add("shift-in");
				} else {
					s.classList.remove("shift-in");
					s.classList.remove("active");
				}
				setVisibility(s, !isOpen ? "visible" : "hidden");
			});
		};
		section.appendChild(container);
	}
	scanStarElements();
	openSavedStars(readStarLevel());
};

export const changeDefaultStarLevel = (level:string) => {
	DeleteAllStars();
	openSavedStars(level);
};

setTimeout(loadAllStars,0);
setTimeout(initStarSelection, 0);

const createStarButton = (section:HTMLElement) : HTMLAnchorElement => {
	const a = document.createElement("a");
	a.classList.add("bubble", "star");
	let ct = section.getAttribute("content-type") || "";
	// we need to differ the embed-ct between h5p and video
	if (ct === "embedding") {
		ct = section.getAttribute("embedding-type") || "0";
	}
	// changed for geografie project
	if (Configuration.closedStarDefault) {
		const level = section.getAttribute("star-level") as string;
		a.classList.add(`level-${level}`);
		a.innerHTML = `<default-icon/>`;
	} else {
		a.innerHTML = getImage("close", "") + getImage("0", "schliessen");
	}
	return a;
};

const getImage = (icon:keyof typeof iconImages, title:string) => `<img title="${title}" src="${iconImages[icon] || iconImages.close}" />`;

const createStarButtons = (starElements:HTMLElement[], section:HTMLElement) => {
	return starElements.map(starElement => {
		const a =  document.createElement("a");
		const StarElementID = starElement.getAttribute("content-type-id")|| "";
		let ct = starElement.getAttribute("content-type") || "";
		// we need to differ the embed-ct between h5p and video
		if (ct === "embedding") {
			ct = starElement.getAttribute("embedding-type") || "";
		}
		a.setAttribute("content-type", ct);
		a.classList.add("bubble");
		a.style.visibility = "hidden";
		const {icon, title} = getIconFromContentType(isImage(starElement) ? "image" : ct);
		const levelIcon = getLevelIcon(starElement.getAttribute("star-level") || "");
		a.innerHTML = getImage(icon, "") + getImage(levelIcon, title);
		a.setAttribute("title", title);
		a.setAttribute("linked-star-element", StarElementID);
		a.setAttribute("level", levelIcon);
		a.onclick = () => {
			if(!a.classList.contains("shift-in")){return;}
			// reset all other star elements and stop media form playing
			starElements.filter(se => !se.isSameNode(starElement)).forEach(se => {
				se.classList.remove("star-active");
				se.style.top = "";
				stopAllMedia(se);
			});

			// also reset all star-buttons
			resetStarButtons(section);
			a.classList.add("active");
			if (starElement.classList.contains("star-active")) {
				return; // Early return if the starElement is already active
			}
			//add the current selection to the global array
			const sectionID = section.getAttribute("content-type-id");
			const currentID = starElement.getAttribute("content-type-id");
			if (!AllStars.has(currentPage)){
				AllStars.set(currentPage, new Map());
			}
			AllStars.get(currentPage).set(sectionID, currentID);

			setTimeout(SaveAllStars, 0);
			starElement.classList.add("fadein");
			starElement.classList.add("star-active");
			scrollToElement(section, C_SCROLL_OFFSET);

			renderEmbedding(starElement);
			// at last recalculate all elements
			// sometimes, when the Contenttype self is smaller than the list of star buttons
			recalcAllElements();
		};
		return a;
	});
};

const setVisibility  = (element:HTMLElement, visibility:string) => {
	element.style.visibility = visibility;
};

const isVisible = (element:HTMLElement) => element.style.visibility === "visible";

const resetStarElements = (starElements:HTMLElement[]) => {
	starElements.forEach(starElement => {
		starElement.classList.remove("star-active");
		starElement.style.top = "";
		starElement.classList.remove("unblur", "blur");
	});
};

const resetStarButtons = (section:HTMLElement) => {
	const allActiveButtons = section.querySelectorAll(".bubble.active");
	allActiveButtons.forEach(ab => {
		ab.classList.remove("active");
	});
};

/**
 * Determine if a 'text-and-image' section should use the image or text icon.
 * If the section contains a div with the 'text-content', text icon will be used.
 * If doens't contain text and contains figures or figure-rows it will use the
 * image icon.
 */
const isImage = (element:HTMLElement) => {
	const ct = element.getAttribute("content-type");
	if (ct !== "text-image") {
		return false;
	}
	const hasText = element.querySelectorAll("inner-content > .text-content").length > 0;
	if (hasText) {
		return true;
	}
	const hasFigures = element.querySelectorAll("inner-content > figure").length > 0;
	const hasFigureRows = element.querySelectorAll("inner-content > figure-row").length > 0;
	const hasImages = hasFigureRows || hasFigures;
	return hasImages;
};

const getIconFromContentType = (contentType:string) => {
	let title = "";
	let icon:keyof typeof iconImages = "close";
	switch(contentType) {
	case "text-and-image" :
		icon = "text";
		title = "Text / Bild";
		break;
	case "h5p" :
		icon = "quest";
		title = "Interaktion";
		break;
	case "tab-box":
		icon = "quest";
		title = "Interaktion";
		break;
	case "video":
		icon = "video";
		title = "Video";
		break;
	case "audio":
		icon = "audio";
		title = "Audio";
		break;
	case "image": {
		icon = "image";
		title = "Bild(er)";
		break;
	}
	case "gallery": {
		icon = "image";
		title = "Bild(er)";
		break;
	}
	case "exercise": {
		icon = "task";
		title = "Aufgabe";
		break;
	}
	case "download": {
		icon = "download";
		title = "Herunterladen";
		break;
	}
	default:
		icon = "star";
		break;
	}
	return {
		icon,
		title
	};
};

const getLevelIcon = (level:string) => {
	switch(level) {
	case "1" :
		return "1";
	case "2" :
		return "2";
	case "3" :
		return "3";
	default:
		return "2";
	}
};

const getHasStarSection = (starElement:HTMLElement) : HTMLElement | null => {
	if (!starElement){return null;}
	const sibling = starElement.previousElementSibling;
	if (!sibling || !(sibling instanceof HTMLElement)){return null;}
	if (sibling.hasAttribute("with-stars")) {
		return sibling;
	}
	return getHasStarSection(sibling);
};

const reinitEmbedding = (embedding:HTMLElement) => {
	const figureEl = embedding.querySelector("figure");
	const oldWrapper = figureEl?.querySelector("iframe-wrapper");
	const oldLazy = figureEl?.querySelector("lazy-iframe");
	if (oldWrapper) {
		oldLazy?.classList.remove("hidden-embedding-placeholder");
		oldWrapper.remove();
	}
	showEmbeddingSections(figureEl);
};

const renderEmbedding = (element:HTMLElement, isInserted = false) => {

	if (isInserted) {
		const insert = element.querySelector<HTMLElement>(".inserted-content");
		if (!insert) { return;}
		if (insert.classList.contains("video")) {
			reinitEmbedding(insert);
		}
		else if (insert?.classList.contains("h5p")) {
			reinitEmbedding(insert);
		}
		else if (insert?.classList.contains("exercise")) {
			const embeddings = Array.from( element.querySelectorAll<HTMLElement>("exercise-content section"));
			embeddings.forEach(embedding => {
				reinitEmbedding(embedding);
			});
		}

	} else {
		//we need to rebuild the embedding handler on videos
		if (element.getAttribute("embedding-type") === "video") {
			initEmbeddingLinks();
		}
		// we need to dispatch a resize event, if the star-element is an embed
		if (element.getAttribute("embedding-type") === "h5p"){
			reinitEmbedding(element);
		}
		if (element.getAttribute("content-type")  === "exercise") {
			const embeddings = Array.from( element.querySelectorAll<HTMLElement>("exercise-content section"));
			embeddings.forEach(embedding => {
				reinitEmbedding(embedding);
			});
		}
	}
};

const removeOldWrapper = (mediaWrapper:HTMLElement) => {
	if (!mediaWrapper) { return;}
	const wrapper = mediaWrapper.querySelector("media-wrap");
	const figure = mediaWrapper.querySelector("figure");
	const audio = mediaWrapper.querySelector("audio");
	const video = mediaWrapper.querySelector("video");
	if (!wrapper) { return;}
	if (!figure) { return;}
	if (audio) {
		audio.setAttribute("controls", "controls");
		figure.append(audio);
		wrapper.remove();
		initMedia(audio);
	}
	if (video) {
		video.setAttribute("controls", "controls");
		figure.prepend(video);
		wrapper.remove();
		initMedia(video);
	}
};

const reinitMedia = (element:HTMLElement, isInserted = false) => {
	if (!element) { return;}
	if (isInserted) {
		const insSection = element.querySelector<HTMLElement>(".inserted-content");
		if (!insSection) { return;}
		if (insSection.classList.contains("audio")) {
			removeOldWrapper(insSection);
		} else if (insSection.classList.contains("video")) {
			removeOldWrapper(insSection);
		}
		initGallery();
		initLightbox();
	}
};


const openStarButton = (starElement:HTMLElement) => {
	if (!starElement) { return; }
	const parentId = starElement.getAttribute("for-star");
	if(!parentId) { return; }
	const elementId = starElement.getAttribute("content-type-id") || "";
	const section = document.querySelector<HTMLElement>(`section[content-type-id="${CSS.escape(parentId)}"`);
	if(!section){
		throw new Error("Couldn't find starElement section");
	}
	if (starElement.classList.contains("star-active")) {
		return;
	} else {
		section.style.marginBottom = "";
		const isParallel = starElement.getAttribute("star-level");
		if (isParallel === "parallel") { return; }
		const newContent = starElement.querySelector<HTMLElement>("inner-content");
		const oldContent = section.querySelector<HTMLElement>("inner-content");
		if (!oldContent || !newContent) { return;}
		oldContent.classList.add("hidden-content");
		const newNode = newContent.cloneNode(true) as HTMLElement;
		newNode.classList.add("inserted-content");
		const oldCt = starElement.getAttribute("content-type");
		const oldEt = starElement.getAttribute("embedding-type");
		oldCt?newNode.classList.add(oldCt):"";
		oldEt?newNode.classList.add(oldEt):"";
		starElement.classList.add("hidden-content");
		section.append(newNode);


		renderEmbedding(starElement);
		renderEmbedding(section, true);
		reinitMedia(section, true);
		recalcAllElements();
	}
	// first reset all buttons of that element
	resetStarButtons(section);
	//also open the used buttons
	if(Configuration.openedStarDefault) {
		const starButtons = Array.from(document.querySelectorAll<HTMLElement>(`[content-type-id="${CSS.escape(parentId)}"] a.bubble`));
		starButtons.forEach(starButton => {
			// the opener Button has class bubble and star
			if (starButton.classList.contains("star")) {
				starButton.classList.add("open");
			} else {
				starButton.classList.add("shift-in");
				starButton.style.visibility = "visible";
			}
		});
	}
	//highlight the reffered button of that opened Element
	const starButton = document.querySelector<HTMLElement>(`a.bubble[linked-star-element="${CSS.escape(elementId)}"]`);
	if(!starButton){return;}
	starButton.classList.add("active");
	// at last recalculate all elements
	// sometimes, when the Contenttype self is smaller than the list of star buttons

};

// getting the first star element of its level per starSection
const scanStarElements = () => {
	const starElements = Array.from(document.querySelectorAll<HTMLElement>(`[star-level]`));
	const searchLevels: Map<string, boolean> = new Map();
	let currentStarSection = "";
	for (const starElement of starElements) {
		const level = starElement.getAttribute("star-level") as string; // Due to the selector this attribute can be depended upon
		const parent = starElement.getAttribute("for-star") || starElement.getAttribute("content-type-id");
		if (parent === currentStarSection){
			if(!searchLevels.get(level)){
				starElement.setAttribute("first-star-level", level);
				searchLevels.set(level, true);
			}
		} else {
			searchLevels.clear();
			searchLevels.set(level, true);
			starElement.setAttribute("first-star-level", level);
			currentStarSection = parent || "";
		}
	}
};

const closeAllStarelements = () => {
	const starElements = Array.from(document.querySelectorAll<HTMLElement>(`[for-star]`));
	starElements.forEach(se => {
		se.removeAttribute("style");
		se.classList.remove("star-active");
		se.style.top = "";
		stopAllMedia(se);
	});
	//also reset the element exchange
	const sections = Array.from(document.querySelectorAll<HTMLElement>(`[with-stars]`));
	sections.forEach(section => {
		section.removeAttribute("style");
		const insContent = section.querySelector<HTMLElement>("inner-content.inserted-content");
		if (insContent) {
			const oldContent = section.querySelector<HTMLElement>("inner-content.hidden-content");
			oldContent?.classList.remove("hidden-content");
			starElements.forEach(e=>e.classList.remove("hidden-content"));
			insContent.remove();
		}
	});
	recalcAllElements(true);
};

const closeAllButton = () => {
	const allStarButtons = Array.from(document.querySelectorAll<HTMLElement>("a.bubble"));
	allStarButtons.forEach(starButton => {
		starButton.classList.remove("open");
		starButton.classList.remove("active");
		starButton.classList.remove("shift-in");
		if (starButton.style.visibility === "visible") {
			starButton.style.visibility = "hidden";
		}
	});
};

const openSavedStars = (level = "2") => {
	closeAllStarelements();
	closeAllButton();
	// Open the appropriate starElement, either the one explicitly chosen, the one corresponding to the default level, or whatever would be the initial state
	document.querySelectorAll<HTMLElement>(`[with-stars]`).forEach(section => {
		const contentID = section.getAttribute("content-type-id");
		if (AllStars.get(currentPage)?.has(contentID)) {
			const starElementID = AllStars.get(currentPage).get(contentID);
			const starElement = document.querySelector<HTMLElement>(`[content-type-id="${CSS.escape(starElementID)}"]`);
			if(starElement){
				openStarButton(starElement);
			}
		}else {
			let firstStarElement = document.querySelector<HTMLElement>(`[for-star="${contentID}"][first-star-level="${level}"]`);
			if(!firstStarElement){
				firstStarElement = document.querySelector<HTMLElement>(`[for-star="${contentID}"][first-star-level="2"]`);
			}
			if(firstStarElement){
				openStarButton(firstStarElement);
			}
		}
	});
	reinitAllEmbeds();
	recalcAllElements();
	//because openStarButton creates new elements, we need to init glossary again
	initGlossary();
};

const reinitAllEmbeds = () => {
	const allEmbeds = Array.from(document.querySelectorAll<HTMLElement>(`section[content-type="embedding"]`));
	allEmbeds.forEach((section:HTMLElement)=> {
		section.offsetTop;
		reinitEmbedding(section);
	});
};

const recalcElement = (section:HTMLElement, starElement:HTMLElement, setTop = false, fast = false, iterations = 0) => {
	const margin = isMobile()? 60 : 0;
	let time = 300;
	if (fast) {
		time = 1;
	}
	if (setTop) { starElement.style.top = `${section.offsetTop + margin}px`; }
	setTimeout(() => {
		if (setTop) { starElement.style.top = `${section.offsetTop + margin}px`; }
		//need to init, if resolution is mobile
		let hs = 0;
		let hst = 0;
		if (starElement.offsetHeight > section.offsetHeight) {
			hs = starElement.offsetHeight - section.offsetHeight + margin;
		}
		// check for mobile resolution
		if (!isMobile()) {
			const starlength = parseInt(section.getAttribute("with-stars") || "2");
			const hasHelpVideo = section.querySelectorAll("help-video").length > 0;
			const hasLocation = section.previousElementSibling?.getAttribute(`content-type`) === "location";
			const starSize = (starlength + (hasHelpVideo? 2 : 1) + (hasLocation? 1 : 0)) * 64;
			if(starSize > section.offsetHeight) {
				hst = starSize - section.offsetHeight + margin;
			}
		}
		// sometimes, when the Contenttype self is smaller than the list of star buttons
		section.style.marginBottom = ((hs > hst)? hs : hst) + 60 + "px";
		const isParallel = starElement.getAttribute("star-level");
		if (isParallel === "parallel") {
			section.style.marginTop = starElement.offsetHeight - margin + "px";
		}
	}, (iterations + 2) * time);
};

const recalcAllElements = (fast = false) => {
	const openInserts = Array.from(document.querySelectorAll<HTMLElement>(".inserted-content"));
	const openStars = Array.from(document.querySelectorAll<HTMLElement>(".star-active"));
	if (!openStars){ return;}
	openInserts.forEach((openInsert, i) => {
		const section = openInsert.parentElement;
		if (!section) { return;}
		recalcElement(section, openInsert, false, false, i);
	});
	openStars.forEach( (openStarEle, i) => {
		const openStar = openStarEle;
		const section = getHasStarSection(openStar);
		if (!section) { return; }
		recalcElement(section, openStar, true, fast, i);
	});
};

const stopMedia = (starElement:HTMLElement, ct:string) => {
	if (!starElement || !ct) {return;}
	if (starElement.getAttribute("content-type") === ct) {
		const mediaFrame = starElement.querySelector<HTMLVideoElement | HTMLAudioElement>(ct);
		if (mediaFrame) {
			mediaFrame.pause();
			mediaFrame.currentTime = 0;
		}
	}
};

const stopAllMedia = (starElement:HTMLElement) => {
	if (!starElement) { return;}
	if (starElement.getAttribute("embedding-type") === "h5p") {
		const h5pFrame = starElement.querySelector("iframe-wrapper");
		if (h5pFrame) {
			h5pFrame.remove();
			const lazyFrame = starElement.querySelector("lazy-iframe");
			lazyFrame?.classList.remove("hidden-embedding-placeholder");
		}
	}
	if (starElement.getAttribute("embedding-type") === "video") {
		const videoFrame = starElement.querySelector("iframe-wrap");
		if (videoFrame) {
			videoFrame.remove();
			const link = starElement.querySelector("a.embedding-link");
			link?.classList.remove("hidden-video-placeholder");
		}
	}
	stopMedia(starElement,"video");
	stopMedia(starElement,"audio");
};

//we need this global timer to reset it
let timer:any = 0;
//on resize, we wait 100ms before we recalculate all starElements and dont spam the script
window.addEventListener("resize", ()=>{
	//clearig all other resize steps
	clearTimeout(timer);
	timer = setTimeout(() => {
		recalcAllElements();
	}, 100);
});

//edited, because there are many changes on 1062px
const isMobile = () => {
	return window.innerWidth <= 1062;
};

